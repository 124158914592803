export const NRC_TS_CODES = {
  "၁": [
    "ကမန",
    "ခဖန",
    "ခဘဒ",
    "ဆပဘ",
    "ဆလန",
    "တနန",
    "နမန",
    "ပတအ",
    "ဗမန",
    "မကတ",
    "မကန",
    "မခဘ",
    "မစန",
    "မညန",
    "မမန",
    "ရကန",
    "ဝမန",
    "အဂယ"
  ],
  "၂": [
    "ဒမဆ",
    "ဖဆန",
    "ဖရဆ",
    "ဘလခ",
    "မစန",
    "ရတန",
    "ရသန",
    "လကန"
  ],
  "၃": [
    "ကကရ",
    "ကဆက",
    "ဖပန",
    "ဘအန",
    "မဝတ",
    "လဘန",
    "သတန"
  ],
  "၄": [
    "ကပလ",
    "တဇန",
    "တတန",
    "ထတလ",
    "ပလဝ",
    "ဖလန",
    "မတန",
    "မတပ",
    "ဟခန"
  ],
  "၅": [
    "၀သန",
    "ကနန",
    "ကဘလ",
    "ကလတ",
    "ကလထ",
    "ကလန",
    "ကလဝ",
    "ကသန",
    "ခတန",
    "ခဥတ",
    "ခဥန",
    "စကန",
    "ဆလက",
    "တဆန",
    "တမန",
    "ထခန",
    "ဒပယ",
    "နယန",
    "ပလန",
    "ပလဘ",
    "ဖပန",
    "ဗမန",
    "ဘတလ",
    "မကန",
    "မမတ",
    "မမန",
    "မရန",
    "မလန",
    "ယမပ",
    "ရဘန",
    "ရဥန",
    "လရန",
    "လဟန",
    "ဝလန",
    "ဟမလ",
    "အတန",
    "အရတ"
  ],
  "၆": [
    "ကစန",
    "ကသန",
    "တသရ",
    "ထဝန",
    "ပလန",
    "ဘပန",
    "မမန",
    "ရဖန",
    "လလန",
    "သရခ"
  ],
  "၇": [
    "ကကန",
    "ကတခ",
    "ကပက",
    "ကဝန",
    "ဇကန",
    "ညလပ",
    "တငန",
    "ထတပ",
    "ဒဥန",
    "နတလ",
    "ပခတ",
    "ပခန",
    "ပတတ",
    "ပတန",
    "ပမန",
    "ဖမန",
    "မညန",
    "မလန",
    "ရကန",
    "ရတန",
    "ရတရ",
    "လပတ",
    "ဝမန",
    "သကန",
    "သနပ",
    "သဝတ",
    "အတန",
    "အဖန"
  ],
  "၈": [
    "ကမန",
    "ခမန",
    "ဂဂန",
    "ငဖန",
    "စတရ",
    "စလန",
    "ဆပဝ",
    "ဆဖန",
    "ဆမန",
    "တတက",
    "ထလန",
    "နမန",
    "ပခက",
    "ပဖန",
    "ပမန",
    "မကန",
    "မတန",
    "မထန",
    "မဘန",
    "မမန",
    "မလန",
    "မသန",
    "ရစက",
    "ရနခ",
    "သရန",
    "အလန"
  ],
  "၉": [
    "ကဆန",
    "ကပတ",
    "ခမစ",
    "ခအဇ",
    "ငဇန",
    "စကတ",
    "စကန",
    "ဇဗသ",
    "ဇယသ",
    "ညဥန",
    "တကန",
    "တတဥ",
    "တသန",
    "ဒခသ",
    "နထက",
    "ပကခ",
    "ပဗသ",
    "ပဘန",
    "ပမန",
    "ပသက",
    "ပဥလ",
    "မကန",
    "မခန",
    "မတရ",
    "မထလ",
    "မနတ",
    "မနမ",
    "မမန",
    "မရတ",
    "မရမ",
    "မလန",
    "မသန",
    "မဟမ",
    "ရမသ",
    "လဝန",
    "ဝတန",
    "သစန",
    "သပက",
    "အမဇ",
    "အမရ",
    "ဥတသ"
  ],
  "၁၀": [
    "ကထန",
    "ကမရ",
    "ခဆန",
    "ပမန",
    "ဘလန",
    "မဒန",
    "မလမ",
    "ရမန",
    "သထန",
    "သဖရ"
  ],
  "၁၁": [
    "ကတန",
    "ကဖန",
    "ဂမန",
    "စတန",
    "တကန",
    "ပဏတ",
    "ပတန",
    "ဘသတ",
    "မတန",
    "မပတ",
    "မပန",
    "မအန",
    "မဥန",
    "ရဗန",
    "ရသတ",
    "သတန",
    "အမန"
  ],
  "၁၂": [
    "ကကက",
    "ကခက",
    "ကတတ",
    "ကတန",
    "ကမတ",
    "ကမန",
    "ကမရ",
    "ခရန",
    "စခန",
    "ဆကခ",
    "ဆကန",
    "တကန",
    "တတန",
    "တမန",
    "ထတပ",
    "ဒဂဆ",
    "ဒဂတ",
    "ဒဂန",
    "ဒဂမ",
    "ဒဂရ",
    "ဒပန",
    "ဒလန",
    "ပဇတ",
    "ပဘတ",
    "ဗတထ",
    "ဗဟန",
    "မဂတ",
    "မဂဒ",
    "မဘန",
    "မရက",
    "ရကန",
    "ရပသ",
    "လကန",
    "လမတ",
    "လမန",
    "လသန",
    "လသယ",
    "သကတ",
    "သခန",
    "သဃက",
    "သလန",
    "အစန",
    "အလန",
    "ဥကတ",
    "ဥကမ"
  ],
  "၁၃": [
    "ကကန",
    "ကခန",
    "ကတန",
    "ကမန",
    "ကလန",
    "ကလန",
    "ကသန",
    "ကဟန",
    "ဆဆန",
    "ညရန",
    "တကန",
    "တခလ",
    "တယန",
    "နခတ",
    "နခန",
    "နစန",
    "နဆန",
    "နမတ",
    "ပတယ",
    "ပယန",
    "ပလန",
    "ဖခန",
    "မကန",
    "မခန",
    "မဆတ",
    "မဆန",
    "မတတ",
    "မတန",
    "မနန",
    "မပတ",
    "မပန",
    "မဖန",
    "မဘန",
    "မမတ",
    "မမန",
    "မယတ",
    "မယန",
    "မရတ",
    "မရန",
    "ရငန",
    "ရစန",
    "လကန",
    "လခတ",
    "လခန",
    "လရန",
    "လလန",
    "သနန",
    "သပန",
    "ဟပန",
    "ဟပန"
  ],
  "၁၄": [
    "ကကထ",
    "ကကန",
    "ကခန",
    "ကပန",
    "ကလန",
    "ငပတ",
    "ဇလန",
    "ညတန",
    "ဒဒရ",
    "ဓနဖ",
    "ပတန",
    "ပသန",
    "ဖပန",
    "ဘကလ",
    "မမက",
    "မမန",
    "မအန",
    "မအပ",
    "ရကန",
    "လပတ",
    "လမန",
    "ဝခမ",
    "သပန",
    "ဟသတ",
    "အဂပ",
    "အမန"
  ]
};

export const NRC_TYPES = ['နိုင်', 'ဧည့်', 'ပြု', 'စ', 'သ', 'သီ'];

export const NRC_SR = ['၁', '၂', '၃', '၄', '၅', '၆', '၇', '၈', '၉', '၁၀', '၁၁', '၁၂', '၁၃', '၁၄'];
