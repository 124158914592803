import {
  InputLabel,
  Button,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import { Delete, } from '@material-ui/icons';
import { useRef, useState } from 'react';

const FILE_SIZE_LIMIT = 5;  // In megabytes

export default function Upload({
  id,
  label,
  value,
  onChange,
  required,
  helperText,
}) {
  const inputRef = useRef();
  const [file, setFile] = useState(null);
  const [internalHelperText, setInternalHelperText] = useState(null);

  const openFileBrowser = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const f = e.target.files[0];
    if (f) {
      if (!/image\/(png|jpe?g)/.test(f.type)) {
        setInternalHelperText('Input must be png or jpeg image.');
        return;
      }

      const fileSize = f.size / (1024 * 1024);  // In megabytes
      if (fileSize > FILE_SIZE_LIMIT) {
        setInternalHelperText(`Maximum allowed file size is ${FILE_SIZE_LIMIT}MB. Your file size is ${fileSize.toFixed(2)}MB.`);
        return;
      }

      const url = URL.createObjectURL(f);
      setFile(url);
      setInternalHelperText(null);

      onChange(f);
    }
  };

  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      {file && (
        <div style={{ display: 'flex', marginTop: 15 }}>
          <img src={file} alt='Uploaded' width='50%' style={{ border: '1px solid rgba(0,0,0, 0.3)', borderRadius: 4 }} />
          <div style={{ margin: '0 5px' }} />
          <IconButton style={{ height: 'fit-content', color: 'red' }} onClick={() => {
            setFile(null);
            onChange(null);
          }}>
            <Delete />
          </IconButton>
        </div>
      )}
      <input type='file' id={id} accept='image/x-png,image/jpeg' ref={inputRef} onChange={handleChange} style={{ display: 'none' }} />

      <label htmlFor={id}>
        <Button variant='contained' color='primary' disableElevation onClick={openFileBrowser} style={{ marginTop: 15 }}>
          ပုံရွေးရန်
        </Button>
      </label>
      <FormHelperText error style={{ marginLeft: 14, marginTop: 4 }}>{helperText || internalHelperText}</FormHelperText>
    </>
  );
}
