export const flattenObject = (ob, { exclude = [] }) => {
  const toReturn = {};

  Object.keys(ob).map(i => {
    if (typeof ob[i] === 'object' && ob[i] !== null && exclude.indexOf(i) === -1) {
      const flatObject = flattenObject(ob[i], { exclude });
      Object.keys(flatObject).map(x => {
        toReturn[`${i}.${x}`] = flatObject[x];
        return x;
      });
    } else {
      toReturn[i] = ob[i];
    }
    return i;
  });
  return toReturn;
}
