import { useRef } from 'react';
import {
  InputLabel,
  Button,
  FormHelperText,
} from '@material-ui/core';
import SignaturePadComponent from 'react-signature-canvas';

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
}

export default function SignaturePad({
  label,
  className,
  onChange = () => { },
  required,
  error,
  helperText,
}) {
  let ref = useRef();

  const generateData = () => {
    const data = ref.current.getTrimmedCanvas().toDataURL('png');
    const blob = dataURItoBlob(data);
    onChange(blob);
  };

  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <div style={{ width: '100%', marginTop: 10 }}>
        <SignaturePadComponent ref={ref} onEnd={generateData} canvasProps={{ className }} />
      </div>
      <div style={{ margin: '10px 0' }} />
      <Button variant='contained' disableElevation onClick={() => {
        ref.current.clear();
        onChange(null);
      }}>
        ပြန်ဖျက်မည်
      </Button>
      <FormHelperText error style={{ marginLeft: 14, marginTop: 4 }}>{helperText}</FormHelperText>
    </>
  );
}
