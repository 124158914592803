import ApiService from '../../services/ApiService';

const registerRecruit = async (doc) => {
  const { data } = await ApiService.post('/recruits', doc);
  return data;
};

const Service = {
  registerRecruit,
};

export default Service;
