import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useForm, Controller } from 'react-hook-form';
import { flattenObject } from '../../utils';
import { fields } from './fields';
import SignaturePad from '../../components/SignaturePad';
import Upload from '../../components/Upload';
import NRCInput from '../../components/NrcInput';
import Service from './service';
import './style.css';

const mode = 'ddummy';

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: '100%',
    marginTop: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    '&> *': {
      margin: '10px 0',
    },
  },
}));

export default function StfForm() {
  const classes = useStyles();
  const history = useHistory();

  const { control, handleSubmit, formState: { errors } } = useForm();

  const [formType, setFormType] = useState('standard');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queries = new URLSearchParams(history.location.search);
    const ft = queries.get('form_type');
    if (ft === 'simple') {
      setFormType(ft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormInput = (formField) => {
    switch (formField.type) {
      case 'nrc':
        return (
          <Controller
            name={formField.key}
            control={control}
            defaultValue=''
            rules={formField.validation || {}}
            render={({ field }) => (
              <>
                <InputLabel required={formField.validation?.required}>{formField.name}</InputLabel>
                {formType === 'simple' ? (
                  <TextField
                    {...field}
                    size='small'
                    variant='outlined'
                    label=''
                    error={!!errors[formField.key]}
                    helperText={errors[formField.key]?.message}
                    placeholder={`${formField.name} ဖြည့်ရန်`}
                    className={classes.textfield}
                  />
                ) : (
                  <NRCInput {...field} className={classes.textfield} size='compact' helperText={errors[formField.key]?.message} />
                )}
              </>
            )}
          />
        );

      case 'date':
        return (
          <Controller
            name={formField.key}
            control={control}
            defaultValue={moment()}
            rules={formField.validation || {}}
            render={({ field }) => (
              <>
                <InputLabel required={formField.validation?.required}>{formField.name}</InputLabel>
                <KeyboardDatePicker
                  {...field}
                  size='small'
                  className={classes.textfield}
                  format='DD MMM yyyy'
                  inputVariant='outlined'
                  error={!!errors[formField.key]}
                />
                <FormHelperText error>{errors[formField.key]?.message}</FormHelperText>
              </>
            )}
          />
        );

      case 'longText':
        return (
          <Controller
            name={formField.key}
            control={control}
            defaultValue=''
            rules={formField.validation || {}}
            render={({ field }) => (
              <>
                <InputLabel required={formField.validation?.required}>{formField.name}</InputLabel>
                <TextField
                  {...field}
                  size='small'
                  variant='outlined'
                  label=''
                  placeholder={`${formField.name} ဖြည့်ရန်`}
                  error={!!errors[formField.key]}
                  helperText={errors[formField.key]?.message}
                  className={classes.textfield}
                  multiline
                  rows={3}
                />
              </>
            )}
          />
        );

      case 'choice':
        return (
          <Controller
            name={formField.key}
            control={control}
            defaultValue=''
            rules={formField.validation || {}}
            render={({ field }) => (
              <>
                <InputLabel required={formField.validation?.required}>{formField.name}</InputLabel>
                <FormControl error={!!errors[formField.key]} helper variant='outlined' size='small' className={classes.textfield}>
                  <Select
                    {...field}
                    placeholder={`${formField.name} ရွေးရန်`}
                    error={!!errors[formField.key]}
                  >
                    {formField.values.map((val) => (
                      <MenuItem key={val} value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText error style={{ marginLeft: 14 }}>{errors[formField.key]?.message}</FormHelperText>
              </>
            )}
          />
        );

      case 'signature':
        return (
          <Controller
            name={formField.key}
            control={control}
            defaultValue=''
            rules={formField.validation || {}}
            render={({ field }) => (
              <SignaturePad
                {...field}
                label={formField.name}
                className='signaturePad'
                required={!!formField.validation?.required}
                error={errors[formField.key]}
                helperText={errors[formField.key]?.message}
              />
            )}
          />
        );

      case 'upload':
        return (
          <Controller
            name={formField.key}
            control={control}
            defaultValue=''
            rules={formField.validation || {}}
            render={({ field }) => (
              <Upload
                {...field}
                id='image-picker'
                label={formField.name}
                required={!!formField.validation?.required}
                error={errors[formField.key]}
                helperText={errors[formField.key]?.message}
              />
            )}
          />
        );

      default:
        return (
          <Controller
            name={formField.key}
            control={control}
            defaultValue=''
            rules={formField.validation || {}}
            render={({ field }) => (
              <>
                <InputLabel required={formField.validation?.required}>{formField.name}</InputLabel>
                <TextField
                  {...field}
                  size='small'
                  variant='outlined'
                  label=''
                  placeholder={`${formField.name} ဖြည့်ရန်`}
                  error={!!errors[formField.key]}
                  helperText={errors[formField.key]?.message}
                  className={classes.textfield}
                />
              </>
            )}
          />
        );
    }
  };

  const submitForm = async (data) => {
    const formData = new FormData();

    const processed = flattenObject(data, { exclude: ['dob', 'photo', 'signature'] });

    Object.entries(processed).forEach(([k, v]) => {
      if (typeof v === 'string' || typeof v === 'object') {
        formData.append(k, v);
      } else {
        console.log(k, v);
        formData.append(k, v, `${k}.png`);
      }
    });

    try {
      setLoading(true);
      const result = await Service.registerRecruit(formData);
      let { serialNumber = 0, name = '' } = result;
      serialNumber = 'YGS-' + serialNumber.toString().padStart(5, '0');

      history.push('/form_success', { serialNumber, name });
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div className='formSkeleton'>
        <div className='formCover'></div>
        <div className='formBody'>
          <div className='formHeader'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ fontSize: '1.7rem', fontWeight: 600 }}>{mode === 'dummy' ? 'Test Title' : 'Federal Army'}</Typography>
              <div style={{ margin: '5px 0' }} />
              <Typography style={{ fontWeight: 600 }}>{mode === 'dummy' ? 'Test Description' : 'Special Task Force'}</Typography>
            </div>
            {mode === 'dummy' ? (
              <div style={{ width: 100, height: 50, background: '#1d6ed8', borderRadius: 5 }} />
            ) : (
              <img
                width={100}
                src='https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_Burma_%281948%E2%80%931974%29.svg'
                alt='flag'
                style={{ borderRadius: 5 }}
              />
            )}
          </div>
          <div style={{ width: '85%', margin: '25px 0' }}>
            <Divider />
          </div>
          <form
            action=''
            noValidate
            encType='multipart/form-data'
            onSubmit={handleSubmit(submitForm)}
            style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Grid container spacing={3} alignItems='center' justify='center'>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                {fields.map((field, id) => (
                  <Grid item key={id} style={{ width: '85%' }}>
                    {getFormInput(field)}
                  </Grid>
                ))}
              </MuiPickersUtilsProvider>
              <Grid item style={{ width: '85%', marginTop: 20, marginBottom: 50 }}>
                <Button type='submit' variant='contained' color='primary' disableElevation>
                  စာရင်းသွင်းမည်
              </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
        <Typography>စာရင်းသွင်းနေပါသည်</Typography>
      </Backdrop>
    </>
  );
}
