import axios from 'axios';

const ApiService = axios.create({
  baseURL: process.env['REACT_APP_API_ENDPOINT'],
});

// ApiService.interceptors.request.use((config) => {
//   let creds = localStorage.getItem('_cred');
//   if (creds) {
//     creds = JSON.parse(creds);
//   }

//   config.headers.authorization = creds?.token || '';

//   return config;
// }, (err) => {
//   return Promise.reject(err);
// });

export default ApiService;
