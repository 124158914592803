import { BrowserRouter, Switch, Route } from 'react-router-dom';
import StfFormPage from './StfForm';
// import PledgeFormPage from './PledgeForm';
import FormSuccessPage from './FormSuccess';

export default function MainRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact>
          <StfFormPage />
        </Route>
        {/* <Route path='/pledge' exact>
          <PledgeFormPage />
        </Route> */}
        <Route path='/form_success' exact>
          <FormSuccessPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
