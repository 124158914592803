export const fields = [
  {
    key: 'photo',
    name: 'ဓါတ်ပုံ',
    type: 'upload',
    validation: {
      required: 'Photo is required.',
    },
  },
  { key: 'name', name: 'အမည်', type: 'text', validation: { required: 'Name is required.' } },
  { key: 'codeName', name: 'ရဲဘော်အမည်', type: 'text' },
  { key: 'nrc', name: 'မှတ်ပုံတင်အမှတ်', type: 'nrc', validation: { required: 'NRC number is required.' } },
  { key: 'fatherName', name: 'အဘအမည်', type: 'text' },
  { key: 'dob', name: 'မွေးသက္ကရာဇ်', type: 'date', validation: { required: 'Date of birth is required.' } },
  { key: 'bloodType', name: 'သွေးအုပ်စု', type: 'choice', values: ['O', 'O−', 'O+', 'A', 'A−', 'A+', 'B', 'B−', 'B+', 'AB', 'AB−', 'AB+'], validation: { required: 'Blood type is required.' } },
  { key: 'education', name: 'ပညာအရည်အချင်း', type: 'text' },
  { key: 'occupation', name: 'အလုပ်အကိုင်', type: 'text' },
  { key: 'ethnicityReligion', name: 'လူမျိုး/ဘာသာ', type: 'text' },
  { key: 'address', name: 'နေရပ်လိပ်စာ', type: 'longText' },
  { key: 'phone', name: 'ဖုန်းနံပါတ်', type: 'text', validation: { required: 'Phone number is required.' } },
  { key: 'secondaryPhone', name: 'တဆင့်ဆက်သွယ်ရန်ဖုန်းနံပါတ်', type: 'text' },
  { key: 'signature', name: 'ထိုးမြဲလက်မှတ်', type: 'signature' },
];
