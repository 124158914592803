import {
  Typography,
  Divider,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import './style.css';

const mode = 'ddummy';

export default function FormSuccessPage() {
  const history = useHistory();
  const state = history.location.state || {};
  const {
    serialNumber = '', name = '',
  } = state;

  return (
    <div className='formSkeleton'>
      <div className='formCover'></div>
      <div className='formBody'>
        <div className='formHeader'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ fontSize: '1.7rem', fontWeight: 600 }}>{mode === 'dummy' ? 'Test Title' : 'စာရင်းသွင်းပြီးကြောင်း'}</Typography>
          </div>
          {mode === 'dummy' ? (
            <div style={{ width: 100, height: 50, background: '#1d6ed8', borderRadius: 5 }} />
          ) : (
            <img
              width={100}
              src='https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_Burma_%281948%E2%80%931974%29.svg'
              alt='flag'
              style={{ borderRadius: 5 }}
            />
          )}
        </div>
        <div style={{ width: '85%', margin: '25px 0' }}>
          <Divider />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography style={{ fontWeight: 500, textAlign: 'center' }}>
            {mode === 'dummy' ? 'Test Description' : 'သင့်အား Federal Army - Special Task Force တွင်ပါဝင်ရန် စာရင်းသွင်းပြီးပါပြီ။'}
          </Typography>
          <Typography style={{ fontWeight: 500, marginTop: 15 }}>
            {mode === 'dummy' ? 'Test Description' : <span>အမည် - <b>{name}</b></span>}
          </Typography>
          <Typography style={{ fontWeight: 500, marginTop: 15 }}>
            {mode === 'dummy' ? 'Test Description' : <span>ဖောင်အမှတ် - <b>{serialNumber}</b></span>}
          </Typography>
        </div>
      </div>
    </div>
  );
}
